import consumer from "./consumer"

window.read_record_count = 0;
window.ac_reader = consumer.subscriptions.create("ReaderChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
    console.log("Connected to the channel!");
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(msg) {
    // Called when there's incoming msg on the websocket for this channel
    console.log("Recieving:")
    console.log("RC Rcv: ", "event_type", msg.event_type, "msg", msg)
    if (msg.event_type == "got_card_depot_id") {
	    setTimeout(function() {
		    console.log("Run Forest Run:", $.fn.jquery)
		    $("#card_uid").text(msg.message)
		    $("#card_uid").removeClass("card-uid-not-ready")
		    $("#card_uid").addClass("card-uid-ready")
		    $("#card_uid_submit").removeAttr("disabled")
		    $("#card_uid_submit").val("Associate Card")
		    $("#associate_card_id").val(msg.card_id)
		}, 100);


  	// TODO: need to park this data in webform or in JS for assocaition with farmer
    }
    if (msg.event_type == "ffrelay_set_mode") {
	window.read_record_count = 0;
      $("#card_log_read_record_count").text(window.read_record_count)
      $("#batch_command_response_message").text("Waiting")
    }
    if (msg.event_type == "card_log_upsert") {
	window.read_record_count++;
      $("#card_log_last_updated_at").text(msg.card_log.updated_at)
      $("#card_log_read_record_count").text(window.read_record_count)
    }
    if (msg.event_type == "batch_command_response") {
	if (msg.data.success) {
	      $("#batch_command_response_message").text("Finished - OK")
	      alert('Job is Done.');
	} else {
	      $("#batch_command_response_message").text("Finished - With Error")
	      alert('Job Failed. Please retry.');
	}
    }

  },
   

  set_ffrelay_mode_read_depot_id() {
	this.perform("set_ffrelay_mode", {mode: "READ_DEPOT_ID"})
  },

  set_ffrelay_mode_read_records() {
	window.read_record_count = 0;
	this.perform("set_ffrelay_mode", {mode: "READ_RECORDS"})
  },

});


function update_card_uid(val) {
    console.log("UCU", val);
    $("#card_uid").text(val);
}

window.read_depot_id = function() {
    window.ac_reader.set_ffrelay_mode_read_depot_id()
    $("#scanner_activated").text("Activated!")
}
window.read_records = function() {
    $("#scanner_activated").text("Activated!")
    window.ac_reader.set_ffrelay_mode_read_records()
}
