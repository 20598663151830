// Load Active Admin's styles into Webpacker,
// see `active_admin.scss` for customization.
import "../stylesheets/active_admin";

import "@activeadmin/activeadmin";
   
//=require jquery.ui.all

// For Action Cable websockets
require("channels")

// For custom logic
console.log("something something");

$(document).ready(function() {
  jQuery('input.hasDatePicker').datepicker({
    dateFormat: "yy-mm-dd",
    beforeShow: function () {
      setTimeout(
        function () {
          $('#ui-datepicker-div').css("z-index", "3000");
        }, 100
      );
    }
  });
  jQuery('#tf_search').click(function() {
    var start_date = jQuery('#start_date').val();
    var end_date = jQuery('#end_date').val();
    if (start_date != '' && end_date != '') {
      if (start_date >= end_date) {
      	alert('The start date must to be earlier then the end date.');
      } else {
        var st = new Date($('#start_date').val());
        var et = new Date($('#end_date').val());
        var dateDiff = (et.getTime() - st.getTime())/(1000*3600*24);
	
	if (dateDiff > 31) {
      	  alert('The maximum date range for reporting is 31 days.');
	} else {
	  window.open('about:blank').location.href = "/report/factory/" + jQuery('#start_date').val() + "/to/" + jQuery('#end_date').val();
	}
      }
    } else {
      alert('Please select the dates');
    }
  })
  jQuery('#farmer_planted_at_3i').val(1);
});
